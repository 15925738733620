import logo from './logo.svg';
import ReactPlayer from 'react-player/vimeo';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className="hero-video-container">
          <ReactPlayer
            url="https://vimeo.com/800192539/56d8e5f53c?share=copy"
            className="react-player"
            playing
            loop
            muted
            playsinline={true}
            width="100%"
            height="100%"
          />
        </div>
      </header>
    </div>
  );
}

export default App;
